@import '~flowbite';
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}

.App {
  text-align: center;
}
 #modal-preferred {
   height: 100%;
 }
.search-input input{
    background: white;
    font-size: 20px;
    font-weight: bold;
    padding: 20px 45px 20px 25px;
    border: solid 1px transparent;
    box-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.05), 0px 4px 6px -1px rgba(0, 0, 0, 0.10);
}
.search-input input::placeholder {
    font-weight: normal;
}
.search-input input:focus{
    border: solid 1px #0052A8;
    /*box-shadow: 0 0 0 1px #0052A8;*/
}
.table-search-result tr td:first-child{
    font-weight: bold;
    color: #111928;
}
.avatar{
    border-radius: 100px;
    background: #0052A8;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/**/
.pattern{
    position: fixed;
    bottom: 0;
    width: 100%;
}
/* dropdown user */
.dropdown-user{
    width: 260px;
    z-index: 99;
    background: white;
    position: absolute;
    right:0;
    top: 42px;
    border-radius: 10px;
}
.dropdown-user ul{
    display: flex;
    flex-direction: column;
}
.dropdown-user ul li{
    padding: 10px;
}
.dropdown-user ul li:first-of-type{
    border-bottom: solid 1px #E5E7EB;
    color: #0052A8;
}
.dropdown-user .clickable:hover{
    background: #F3F4F6;
    cursor: pointer;
}
/*mobile*/
@media screen and (max-width: 390px) {
    .cRzBiF {
        margin: 0 1rem 0 1rem !important;
    }
}