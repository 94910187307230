.detail-page {
  z-index: 99;
  position: relative;

  .client-name {
    background: #0052A8;
    border-left: solid 8px #000000;
    padding: 20px 0;

    div {

    }

    .custom-color {
      padding-top: 8px;
      padding-bottom: 8px;
      .text-gray-500, .text-gray-900 {
        color: #ffffff !important;
      }
    }
  }
}